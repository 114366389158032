// @flow
import queryString from 'query-string';
import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';

export const pxAsNumber = (px: string): number => parseInt(px.replace('px', ''), 10);

export const getKeyByValue = (
  object: { [key: string]: number | string },
  value: number | string
): ?string => Object.keys(object).find((key: string) => object[key] === value);

export const hexToRgb = (hex: string): string => {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

export const getLatitudePersonalLoanApplyUrl = (
  options: { amount?: number, term?: number | string, purpose?: string } = {},
  appendAnalytics: boolean = true
) => {
  const baseUrl = 'https://lfs-apply.mypersonalloanapp.com/gem';
  let searchString;

  options.term = getLoanTermText(parseInt(options.term, 10));

  if (appendAnalytics) {
    searchString = queryString.stringify(
      Object.assign(
        {},
        options,
        getGoogleAnalyticsParam(),
        getAdobeAnalyticsParam(baseUrl)
      )
    );
  } else {
    searchString = queryString.stringify(options);
  }

  return searchString ? `${baseUrl}?${searchString}` : baseUrl;
};

export const getLoanTermText = (term: number): string => {
  if (typeof term !== 'number' || isNaN(term) || term < 0) {
    return '';
  }
  return `${term} year${term > 1 ? 's' : ''}`;
};

export const stringToBoolean = (value: string): string | boolean => {
  if (value.toLowerCase() === 'true') return true;
  if (value.toLowerCase() === 'false') return false;
  return value;
};

/*
 * Returns an object containing the Google Analytics Client ID
 *
 * Calling this function returns an object with key and values representing query param name and value respectively.
 * {
 *   _ga: '2.156895549.786352505.1571118984-861204910.1571118984'
 * }
 */
export const getGoogleAnalyticsParam = () => {
  let params = {};
  if (typeof ga !== 'undefined') {
    // eslint-disable-next-line no-undef, flowtype/require-parameter-type
    ga((tracker) => {
      const qs = tracker.get('linkerParam').split('&');
      // eslint-disable-next-line flowtype/no-weak-types
      params = qs.reduce((obj: object, value: string) => {
        const parts = value.split('=');
        obj[parts[0]] = parts[1];
        return obj;
      }, {});
    });
  }
  return params;
};

/*
 * Returns an object containing the Adobe Analytics Client ID
 *
 * Calling this function returns an object with key and values representing query param name and values respectively.
 * {
 *   adobe_mc: '2.156895549.786352505.1571118984-861204910.1571118984'
 * }
 */
export const getAdobeAnalyticsParam = (baseUrl: string) => {
  if (typeof Visitor !== 'undefined') {
    const visitor = Visitor.getInstance('B6D9B74F57B2FBE97F000101@AdobeOrg', {
      trackingServer: 'metrics.latitudefinancial.com',
      trackingServerSecure: 'smetrics.latitudefinancial.com',
      marketingCloudServer: 'metrics.latitudefinancial.com',
      marketingCloudServerSecure: 'smetrics.latitudefinancial.com'
    });

    const destinationURLWithVisitorIDs = visitor.appendVisitorIDsTo(baseUrl);

    return destinationURLWithVisitorIDs;
  }

  return baseUrl;
};

export const parseContent = (content: Item.content) => {
  if (React.isValidElement(content)) {
    return content;
  }
  if (typeof content === 'string') {
    return ReactHTMLParser(content);
  }
  return '';
};

/**
 * Accepts a number value and converts it to a readable percentage. Defaults to two decimal places.
 * Useful for displaying interest rates.
 * Returns a string.
 *
 * eg '10.5773' returns '10.58%'
 * eg '0.23' returns '0.23%'
 */

export const numberToPercentage = (number: string, decimalPlaces: number = 2) =>
  `${parseFloat(number).toFixed(decimalPlaces)}%`;


  /**
 * Handle smooth scroll functionality to given element/selector
 */

export const handleSmoothScroll = (selector, offset = 0) => {
  if (typeof document !== 'undefined') {
    const anchorEl = document.querySelector(selector);

    if (anchorEl) {
      window.scrollTo({
        top: anchorEl.offsetTop - offset,
        behavior: 'smooth'
      });
    }
  }
};

/**
 * Choose which data source to render Alert
 * when Alert Data is valid in both of Page Body and Template, we use Page Body data
 * when Alert Data is only valid in Page Body, we use Page Body data
 * when Alert Data is only valid in Template, we use Template data
 * when Alert Data is invalid in both of Page Body and Template, we return Null to call back
 */
export const selectDataSourceForAlertBanner = (pageData) => {
  const insideAlert = pageData?.body?.filter(
    item => item.contentType.sys.id === CONTENTFUL_COMPONENTS.ALERT
  );
  return insideAlert?.length > 0
    ? insideAlert[0]
    : pageData?.template?.alert;
};
