// @flow

import React, { useEffect, useState } from 'react';
import { Link } from '@latitude/link';
import styled from 'styled-components';
import {
  MARGIN,
  PADDING,
  BUTTON_STYLE,
  FONT_WEIGHT,
  FONT_SIZE,
  FLEX_WRAP,
  BREAKPOINT,
  FLEX_DIRECTION,
  DISPLAY,
  ALIGN_ITEMS,
  FONT_FAMILY,
  JUSTIFY_CONTENT,
  COLOR,
  ALIGN,
  LINE_HEIGHT
} from '@latitude/core/utils/constants';
import ListNumbered from '@latitude/list-numbered/ListNumbered';
import { Metadata } from '@latitude/metadata';
import Section from '@latitude/section';
import { Box } from '@latitude/box';
import { Heading5, Heading4 } from '@latitude/heading';
import { List, ListItem } from '@latitude/list';
import SvgInline from '@latitude/svg-inline';
import { Text } from '@latitude/text';
import BrandedCallout from '@latitude/callout/BrandedCallout';
import Layout from '../../components/layout';
import { CardGroup, CardGroupItem } from '../../components/CardGroup/CardGroup';
import Lframe from '../../components/Lframe/Lframe';
import Modal from '../../components/Modal/Modal';
import Image from '../../components/Image/Image';
import { getAdobeAnalyticsParam } from '../../utils/helpers';

type InfoBoxProps = {
  title: string,
  children: React.Node
};

const InfoBox = ({ title, children }: InfoBoxProps) => (
  <Box backgroundColor={COLOR.WHITE} padding={PADDING.P24} height="100%">
    <Title color={COLOR.BLUE_DEEP} align={ALIGN.CENTER}>
      {title}
    </Title>
    {children}
  </Box>
);

const Title = styled(Heading5)`
  font-size: 24px;
  margin-bottom: ${MARGIN.M24};
`;

const StyledBoxTop = styled(Box)`
  padding-bottom: ${PADDING.P32};

  @media (min-width: ${BREAKPOINT.SM}) {
    padding: ${PADDING.P48} 0 ${PADDING.P64};
  }
`;

const StyledLinkContainer = styled(Box)`
  display: ${DISPLAY.FLEX};
  padding-top: ${PADDING.P24};
  justify-content: ${JUSTIFY_CONTENT.CENTER};

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: ${PADDING.P0};
    justify-content: ${JUSTIFY_CONTENT.FLEX_END};
    align-items: ${ALIGN_ITEMS.CENTER};
    height: 100%;
  }
`;

const StyledBoxBottom = styled(Box)`
  padding: ${PADDING.P48} 0 ${PADDING.P48};

  @media (min-width: ${BREAKPOINT.SM}) {
    padding: ${PADDING.P64} 0 ${PADDING.P64};
  }
`;

const HeroContainer = styled(Box)`
  position: relative;
`;

const HeadingContainer = styled(Box)`
  background-color: ${COLOR.BLUE};
  padding: ${PADDING.P16} 0;

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: ${PADDING.P24} 0;
  }
`;

const BasicHeader = styled(Box)`
  height: 80px;

  @media (min-width: ${BREAKPOINT.LG}) {
    height: 72px;
  }
`;

const StyledFooter = styled.footer`
  padding: ${PADDING.P24} 0 ${PADDING.P32};
  background-color: ${COLOR.GREY5};
  font-size: ${FONT_SIZE.SMALL};

  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: ${PADDING.P32};
  }
`;

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: ${MARGIN.M16};
  display: ${DISPLAY.FLEX};
  flex-wrap: ${FLEX_WRAP.WRAP};
  justify-content: ${JUSTIFY_CONTENT.CENTER};

  li {
    padding: 0 ${PADDING.P8};
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: 0;
    justify-content: ${JUSTIFY_CONTENT.FLEX_START};

    li {
      padding: 0 ${PADDING.P16} 0 0;
    }
  }
`;

const StyledDisclaimerText = styled.p`
  text-align: ${ALIGN.CENTER};
  line-height: ${LINE_HEIGHT.TIGHT.SMALL};
  margin: 0;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-bottom: 7px;
    text-align: ${ALIGN.LEFT};
  }
`;

const StyledPadlockContainer = styled.div`
  display: ${DISPLAY.FLEX};
  justify-content: ${JUSTIFY_CONTENT.CENTER};
  align-items: ${ALIGN_ITEMS.FLEX_END};

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-right: 12px;
  }
`;

const StyledFooterInner = styled.div`
  @media (min-width: ${BREAKPOINT.LG}) {
    display: ${DISPLAY.FLEX};
    flex-direction: ${FLEX_DIRECTION.ROW};
  }
`;

const StyledHomeLink = styled(Link)`
  display: flex;
  align-self: center;

  .logo {
    height: 48px;
  }

  /* required inside flex container for IE11 */
  svg.home-button__logo {
    display: block;
    flex: 0 0 auto;
    width: 164px;
    height: 27px;
  }
`;

const StyledButtonContainer = styled.div`
  max-width: 255px;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: none;
  }
`;

const StyledLframe = styled(Lframe)`
  top: 0 !important;
`;

const getLatitudePersonalLoanApplyUrl = (
  options: { subType?: string } = {}
) => {
  return 'https://loans.portal.gemfinance.co.nz/s/quote?amount=30000&purpose=purpose&period=7';
};

// eslint-disable-next-line flowtype/require-parameter-type
export default props => {
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState('');
  const applyUrl = getLatitudePersonalLoanApplyUrl();
  // This hook is important so that view gets the up to date url
  useEffect(() => {
    setUrl(applyUrl);
  }, [applyUrl]);

  useEffect(() => {
    setTimeout(() => {
      const applyUrlWithParam = getAdobeAnalyticsParam(applyUrl);
      setUrl(applyUrlWithParam);
    }, 500);
  }, []);

  return (
    <Layout location={props.location} noHeader noFooter>
      <BasicHeader>
        <div className="container h-100 d-flex">
          <StyledHomeLink noStyle href="/" trackId="nav-home">
            <Image
              className="logo"
              src={require('../../images/gem-latitude-logo-blue.svg')}
              alt="Gem by Latitude"
            />
          </StyledHomeLink>
        </div>
      </BasicHeader>
      <main>
        <Box backgroundColor={COLOR.GREY6}>
          <Metadata
            title="Apply Online for a Personal Loan | Gem by Latitude"
            canonical="/loans/apply/"
            noIndex
          />

          <HeadingContainer>
            <div className="container">
              <Heading4
                color={COLOR.WHITE}
                marginBottom={MARGIN.M0}
                fontFamily={FONT_FAMILY.TITLE}
                fontWeight={FONT_WEIGHT.BOLD}
              >
                Gem Personal Loan application
              </Heading4>
            </div>
          </HeadingContainer>
          <HeroContainer>
            <StyledLframe personalLoanApply />
          </HeroContainer>

          <Section className="w-100" squashed>
            <StyledBoxTop>
              <div className="row pt-lg-30">
                <div className="col-12 offset-lg-4 col-lg-4">
                  <StyledButtonContainer>
                    <Link
                      button={BUTTON_STYLE.SECONDARY}
                      href={url}
                      trackId="start-application-top"
                      trackEventData={{
                        location: 'top'
                      }}
                      disableDefaultFunnelTracking
                      target="_self"
                    >
                      Start Application
                    </Link>
                  </StyledButtonContainer>
                </div>
                <div className="col-12 col-lg-4">
                  <StyledLinkContainer>
                    <Text fontSize={FONT_SIZE.LARGE}>
                      <Link
                        trackId="loan-details"
                        href="#"
                        onClick={() => setShowModal(true)}
                      >
                        View Loan Details
                      </Link>
                    </Text>
                  </StyledLinkContainer>
                </div>
              </div>
            </StyledBoxTop>
            <CardGroup>
              <CardGroupItem col={3}>
                <InfoBox title="Who can apply?">
                  <Text fontWeight={FONT_WEIGHT.BOLD} marginBottom={MARGIN.M8}>
                    You must be:
                  </Text>
                  <List itemMarginBottom={MARGIN.M8}>
                    <ListItem>18 years or over</ListItem>
                    <ListItem>a permanent resident of NZ</ListItem>
                    <ListItem>earning a stable income</ListItem>
                  </List>
                  <Text>
                    Credit and lending criteria, and fees apply including a $240
                    establishment fee.
                  </Text>
                </InfoBox>
              </CardGroupItem>
              <CardGroupItem col={3}>
                <InfoBox title="How the process works">
                  <ListNumbered
                    alignItems={ALIGN_ITEMS.FLEX_START}
                    data={[
                      'Apply online.',
                      'We’ll call to let you know if you have been approved, or if we need any further documentation. This may take 1–2 business days.',
                      'Accept your contract online. You’ll usually get your funds within 24-48 hours.'
                    ]}
                    viewStyle="compact"
                    counterBgColor={COLOR.BLUE_DEEP}
                    counterVerticalAlign
                    separator="light"
                  />
                </InfoBox>
              </CardGroupItem>
              <CardGroupItem col={3}>
                <InfoBox title="What will you need?">
                  <Text fontWeight={FONT_WEIGHT.BOLD}>Income documents</Text>
                  <Text marginBottom={MARGIN.M8}>
                    You may be asked to provide one or more of the following:
                  </Text>
                  <List itemMarginBottom={MARGIN.M8}>
                    <ListItem>Payslip</ListItem>
                    <ListItem>Bank statement</ListItem>
                    <ListItem>Individual tax return</ListItem>
                    <ListItem>Current rental agreement</ListItem>
                  </List>
                  <Text fontWeight={FONT_WEIGHT.BOLD}>ID verification</Text>
                  <Text>
                    If you are new to Gem, we will also need to verify your
                    identity.
                  </Text>
                  <Text marginBottom={MARGIN.M8}>
                    You may be asked to provide one or more documents that
                    verify your:
                  </Text>
                  <List itemMarginBottom={MARGIN.M8}>
                    <ListItem>Current name</ListItem>
                    <ListItem>Date of birth</ListItem>
                    <ListItem>Current address</ListItem>
                  </List>

                  <Text fontWeight={FONT_WEIGHT.BOLD} marginBottom={MARGIN.M8}>
                    Some of the documents that could be used include:
                  </Text>
                  <List itemMarginBottom={MARGIN.M8}>
                    <ListItem>
                      Current New Zealand drivers license / learners permit
                    </ListItem>
                    <ListItem>New Zealand passport</ListItem>
                  </List>
                </InfoBox>
              </CardGroupItem>
            </CardGroup>

            <StyledBoxBottom>
              <div className="row pt-lg-30">
                <div className="col-12 offset-lg-4 col-lg-4">
                  <StyledButtonContainer>
                    <Link
                      button={BUTTON_STYLE.SECONDARY}
                      href={url}
                      trackId="start-application-bottom"
                      trackEventData={{
                        location: 'bottom'
                      }}
                      disableDefaultFunnelTracking
                      target="_self"
                    >
                      Start Application
                    </Link>
                  </StyledButtonContainer>
                </div>
              </div>
            </StyledBoxBottom>
          </Section>

          <BrandedCallout
            cta={
              <Link
                style={{ marginTop: 16 }}
                button={BUTTON_STYLE.SECONDARY}
                href="tel:0800 422 898"
              >
                Call 0800 422 898
              </Link>
            }
            hasConfettiBackground
            heading="Need help? Call us"
            line1="Mon to Fri: 9:00am - 6:30pm (NZST)"
            line2="Weekends: Closed"
          />

          <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            title="Loan Details"
            content={
              <>
                <List>
                  <ListItem>Secured and unsecured loans</ListItem>
                  <ListItem>
                    Fixed rate for the life of the loan, interest rate will
                    depend on your personal circumstances
                  </ListItem>
                  <ListItem>Unsecured loan amounts from $2,000</ListItem>
                  <ListItem>Weekly, fortnightly, monthly repayments</ListItem>
                  <ListItem>6 month &ndash; 7 year loan terms</ListItem>
                  <ListItem>Establishment fee $240</ListItem>
                </List>
                <div className="row pt-5">
                  <div className="col-7 col-md-5 col-lg-3 mx-auto">
                    <Link
                      button={BUTTON_STYLE.SECONDARY}
                      href="#"
                      trackId="modal-close"
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      Close
                    </Link>
                  </div>
                </div>
              </>
            }
            ariaHideApp={false}
          />
        </Box>
      </main>
      <StyledFooter>
        <div className="container">
          <StyledFooterInner>
            <StyledPadlockContainer>
              <SvgInline name="padlock" aria-label="padlock" fillBlueBright />
            </StyledPadlockContainer>
            <div>
              <StyledUl>
                <li>
                  <Link href="/privacy/">
                    Privacy & Credit Reporting Policy
                  </Link>
                </li>
                <li>
                  <Link href="/terms-and-conditions/">
                    Website Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link href="/security/">Security</Link>
                </li>
              </StyledUl>
              <StyledDisclaimerText>
                Gem Personal Loans are provided by Latitude Financial Services
                Limited
              </StyledDisclaimerText>
            </div>
          </StyledFooterInner>
        </div>
      </StyledFooter>
    </Layout>
  );
};
